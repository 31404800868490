import { amyConfigs, initializeAmy } from "@amy-app/amy-app-js-sdk";
import { CssBaseline } from "@material-ui/core";
import firebase from "firebase";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const API_ROOT = process.env.API_ROOT || '';

let firebaseApp: firebase.app.App = firebase.initializeApp(amyConfigs, "amy.app");
const amy = initializeAmy({ firebaseApp });

// Webmad code starts here:

amy.readyObserver(async (ready: boolean) => {
    if(ready){
        // Is is logged in and ready to learn
        // this will list all roles a user has
        console.log("UserId", amy.roles);
        const assignmentId = await amy.startAssignment(["cplx090010"]);
        amy.studentAssignmentObserver(assignmentId, (assignment) => {console.log(assignment)});
    }else{
        // no user is signed in


        const token = await fetchToken();
        await amy.signInViaToken(token);
    }
})



 async function fetchToken(){
    const user = await fetch(`${API_ROOT}/createAmyUser`).then( x => x.json());
    const res = await fetch(`${API_ROOT}/getAmyToken?uid=${user.uid}`).then( x => x.json());
    return res.token;
}
// ends here

//@ts-ignore
window.firebaseApp = firebaseApp;

ReactDOM.render(
    <React.StrictMode>
        <CssBaseline />
        <App />
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
